<template>
  <div>
    <!-- 企业服务信息 -->
    <div class="bottom_right" v-if="i == 1">
      <div class="smalltitle">金融服务信息</div>
      <div class="password">
        <div class="right"><span @click="gettags(2)">添加</span></div>
      </div>
      <div class="tabbox">
             <el-table :data="tableData" style="width: 100%">
        <el-table-column label="服务名称">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>{{ scope.row.Title }}</p>
              <!-- <p>住址: {{ scope.row.address }}</p> -->
              <div slot="reference" class="name-wrapper">
                {{ scope.row.Title }}
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="创建日期">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ timeformart.timeFormat(scope.row.CreateOn,1) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开启/关闭">
          <template slot-scope="scope">
            <!-- <el-popover trigger="hover" placement="top"> -->
            <!-- <p>{{ scope.row.CateName }}</p> -->

            <!-- active-text="开"
               inactive-text="关"  -->
            <div slot="reference" class="name-wrapper">
              <el-switch
                v-model="scope.row.State"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="getserveopen(scope.row)"
              >
              </el-switch>
            </div>
            <!-- </el-popover> -->
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="gettags(8, scope.row)"
              >查看</el-button
            >
            <el-button size="mini" type="primary" @click="gettags(3, scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="!scope.row.State"
              size="mini"
              type="danger"
              @click="deleted(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="currentSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totoPage"
        >
        </el-pagination>
      </div>
      </div>
 
    </div>
    <!-- 发布金融 -->
    <div class="bottom_right" v-if="i == 2">
      <div class="heard">
        <span @click="gettags(1)"
          ><img src="../assets/img/back.png" alt="" /> 返回</span
        >
      </div>
      <div class="smalltitle">填写金融服务信息</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="服务名称" prop="Title">
          <el-input v-model="ruleForm.Title"></el-input>
        </el-form-item>

        <el-form-item label="联系人" prop="ContactPerson">
          <el-input v-model="ruleForm.ContactPerson"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="Tel">
          <el-input v-model="ruleForm.Tel"></el-input>
        </el-form-item>
        <!-- 企业LOGO -->
        <el-form-item label="上传首图" :required="true">
           <span slot="label" >上传首图<span style="color:red;font-size:12px"> (建议尺寸宽380x高200)</span></span>
          <template>
            <div v-if="comLogolist.length > 0">
              <div
                class="demo-upload-list"
                :key="index"
                v-for="(item, index) in comLogolist"
              >
                <template style="height: 150px; width: 150px">
                  <div class="demo-upload-list-cover">
                    <img :src="item.url" class="imputImg" />
                    <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                    <i
                      class="el-icon-circle-close delimg"
                      @click="RemoveLoadd(item)"
                    ></i>
                  </div>
                </template>
              </div>
            </div>
            <!-- <Upload> -->
            <el-upload
              ref="upload"
              v-if="comLogolist.length == 0"
              :before-upload="AddComLogo"
              type="drag"
              action=""
              style="display: inline-block; width: 100%"
            >
              <div style="width: 58px; height: 58px; line-height: 58px">
                <i class="el-icon-camera-solid"></i>
                <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
              </div>
            </el-upload>
            <!-- </Upload> -->
          </template>
        </el-form-item>
        
        <el-form-item label="服务介绍" prop="Description">
          <editor-bar
            v-model="ruleForm.Description"
            :value="ruleForm.Description"
            @change="getEditor"
            @onblur="getIntro"
            ref="weditor"
          ></editor-bar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="cancleeditCom(1)">取消</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 金融服务详细信息 -->
    <div class="bottom_right" v-if="i == 8">
      <div class="heard">
        <span @click="gettags(1)"
          ><img src="../assets/img/back.png" alt="" /> 返回</span
        >
      </div>
      <div class="smalltitle">金融服务详细信息</div>
      <div class="userinforbox">
        <div class="username">
          <span>服务名称：</span>
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.Title"
            placement="top-start"
          >
            <i>{{ item.Title }}</i>
          </el-tooltip>
        </div>

        <div class="username">
          <span>联系人：</span>{{ item.ContactPerson }}
        </div>
        <div class="username"><span>联系方式：</span>{{ item.Tel }}</div>
        <div class="usertitle">服务介绍：</div>
        <div class="htmlbox" v-html="item.Description"  ref="content"
          @click="imageEnlargement"></div>
      </div>
    </div>
    <!-- 编辑金融 -->

    <div class="bottom_right" v-if="i == 3">
      <div class="heard">
        <span @click="gettags(1)"
          ><img src="../assets/img/back.png" alt="" /> 返回</span
        >
      </div>
      <div class="smalltitle">编辑金融服务</div>
      <el-form :model="item" :rules="rules" ref="item" class="demo-ruleForm">
        <el-form-item label="服务名称" prop="Title">
          <el-input v-model="item.Title"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="ContactPerson">
          <el-input v-model="item.ContactPerson"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="Tel">
          <el-input v-model="item.Tel"></el-input>
        </el-form-item>
        <el-form-item label="上传首图" :required="true">
          <span slot="label" >上传首图<span style="color:red;font-size:12px"> (建议尺寸 宽380x高200)</span></span>
          <template>
            <div v-if="LogoList.length > 0">
              <div
                class="demo-upload-list"
                :key="index"
                v-for="(item, index) in LogoList"
              >
                <template style="height: 150px; width: 150px">
                  <div class="demo-upload-list-cover">
                    <img :src="item.url" class="imputImg" />
                    <!-- <img class="delimg"  src="../assets/img/del.png" alt="" /> -->
                    <i
                      class="el-icon-circle-close delimg"
                      @click="RemoveLogo(item)"
                    ></i>
                  </div>
                </template>
              </div>
            </div>
            <!-- <Upload> -->
            <el-upload
              ref="upload"
              v-if="LogoList.length == 0"
              :before-upload="AddFileLogo"
              type="drag"
              action=""
              style="display: inline-block; width: 100%"
            >
              <div style="width: 58px; height: 58px; line-height: 58px">
                <i class="el-icon-camera-solid"></i>
                <!-- <Icon type="ios-camera"
                        size="20"></Icon> -->
              </div>
            </el-upload>
            <!-- </Upload> -->
          </template>
        </el-form-item>
      
        <el-form-item label="服务介绍" prop="introduce">
          <editor-bar
            v-model="item.Description"
            :value="item.Description"
            @change="getserveEditor"
            @onblur="getserveIntro"
            ref="weditor"
          ></editor-bar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addsubmitForm('item')"
            >确定</el-button
          >
          <el-button @click="cancleeditCom(1)">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import EditorBar from "../components/wangeditor";
import timeFormat from "../util/time.js";
var isvalidPhone = function (str) {
  const reg = /^(1[3456789]\d{9})$|^(0\d{2,3}-\d{7,8})$/;
  return reg.test(str);
};
var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else if (!isvalidPhone(value)) {
    callback(new Error("请输入正确的11位手机号码或座机号码"));
  } else {
    callback();
  }
};
export default {
  components: {
    EditorBar,
  },
  data() {
    return {
      tableData: [],
      //   富文本
      editor: null,
      i: 1,
      DeleteImgSysID: "",
      item: {},
      LogoList: [],
      // 企业信息列表
      ruleForm: {},
      comLogolist: [],
      // 企业
      rules: {
        Title: [
          // 企业名称
          { required: true, message: "请输入服务名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
        // 联系人
        ContactPerson: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        // 联系电话
        Tel: [{ required: true, trigger: "blur", validator: validPhone }],
      },
      totoPage: null,
      currentPage: 1,
      currentSize: 5,
      imgArr:[],
    };
  },

  methods: {
     imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

    // 分页
    handleSizeChange(a) {
      console.log(a);
      this.currentSize = a;
      this.getserveList();
    },
    handleCurrentChange(b) {
      console.log(b);
      this.currentPage = b;
      this.getserveList();
    },
    // 添加金融服务
    submitForm(formName) {
      let that = this;

      that.$refs[formName].validate((valid) => {
        if (valid) {
          let CompanySysID = window.localStorage.getItem("CompanySysID");

          let ruleForm = that.ruleForm;
          ruleForm.CompanySysID = CompanySysID;
          //   ruleForm.CompanySysID = "e0ae8590-b1a3-4b10-ad07-9917fe73b40c",
          ruleForm.State = false;

          //   金融服务大图
          let comLogolist = that.comLogolist;
          if (comLogolist.length == 0) {
            that.$message.error("请上传首图");
            return;
          }
          console.log(comLogolist);
          let formData = new FormData();
          comLogolist.forEach((file) => {
            formData.append("图片", file);
          });

          formData.append("Obj", JSON.stringify(ruleForm));
          //   formData.append("DeleteImgSysID", this.DeleteImgSysID);
          that.$post("/api/JRServe/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("添加成功");
              that.getserveList();
              that.i = 1;
              that.ruleForm = {};
              that.comLogolist = [];
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //添加金融服务时上传图片
    AddComLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.comLogolist = this.comLogolist.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },
    // 编辑金融服务信息
    addsubmitForm(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let ruleForm = that.item;
          let LogoList = that.LogoList;
          if (LogoList.length == 0) {
            that.$message.error("请上传首图");
            return;
          }
          console.log(LogoList);
          let formData = new FormData();
          LogoList.forEach((file) => {
            formData.append("图片", file);
          });
          formData.append("Obj", JSON.stringify(ruleForm));
          formData.append("DeleteImgSysID", this.DeleteImgSysID);
          that.$post("/api/JRServe/Edit", formData).then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message.success("编辑服务成功");
              that.getserveList();
              that.i = 1;
              that.item = {};
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //附件列表
    LoadFiles(sysid) {
      if (sysid == "") return;
      this.loading = true;
      let that = this;
      that
        .$post("/api/File/List", {
          MainSysID: sysid,
        })
        .then(function (res) {
          let List = [];
          List = res.Obj;

          List.forEach((i) => {
            that.LogoList.push({
              name: i["SourceName"] + "." + i["Extension"],
              url: that.urlOA + i["URL"].substr(1),
              size: -1,
              SysID: i.SysID,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //编辑删除图片
    RemoveLogo(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.LogoList = this.LogoList.filter((v) => {
              return v != Obj;
            });
            this.DeleteImgSysID = Obj.SysID;
          } else {
            this.LogoList = this.LogoList.filter((v) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },
    RemoveLoadd(Obj) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (Obj.size === -1) {
            this.comLogolist = this.comLogolist.filter((v) => {
              return v != Obj;
            });
            this.DeleteImgSysID = Obj.SysID;
          } else {
            this.comLogolist = this.comLogolist.filter((v) => {
              return v != Obj;
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      // if (this.loading) return;
    },
    //上传图片
    AddFileLogo(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      if (index > 0) {
        let suffixName = fileName.substring(index + 1).toLowerCase();
        if (
          suffixName == "jpg" ||
          suffixName == "jpeg" ||
          suffixName == "png"
        ) {
          this.file = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const _base64 = reader.result;
            file.url = _base64;
            this.LogoList = this.LogoList.concat(file);
          };
        } else {
          this.$Message.warning("上传附件格式有误,请重新上传!");
        }
      }
      return false;
    },

    // 获取企业信息
    getnewsList() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.CompanyObj;
          // 图片处理
          company.Logo = that.urlOA + company.Logo.substr(1);
          // 时间处理
          company.OpenTime = timeFormat.timeFormat(company.OpenTime, 1);
          company.Tags = company.Tags.split(",");

          that.compObj = company;
          console.log(EditorBar.value);
          // EditorBar.value = that.ruleForm.Description

          // 营业执照相关
          // let yyzz = data.Obj.CompanyYYZZList;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 取消编辑企业信息
    cancelserve(i) {
      this.i = i;
      this.addserverList = {};
    },
    cancleeditCom(i) {
      this.item = {};
      this.ruleForm = {};
      this.LogoList = [];
      this.comLogolist = [];
      this.i = i;
      this.getnewsList();
    },
    // 金融列表
    getserveList() {
      let that = this;
      let Sysid = window.localStorage.getItem("CompanySysID");
      that
        .$post("/api/JRServe/List", {
          CState: true,
          CompanySysID: Sysid,
          Sort: "CreateOn desc",
          PageIndex: that.currentPage,
          PageSize: that.currentSize,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj;
          console.log(timeFormat.timeFormat);
          company.forEach(() => {
            // v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
          });

          that.tableData = company;
          that.totoPage = data.RecordCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 开关金融服务
    getserveopen(row) {
      console.log(row);
      let that = this;
      that
        .$post("/api/JRServe/ChangeInfo", {
          SysID: row.SysID,
          State: row.State,
        })
        .then(function (data) {
          console.log(data);
          that.$message.success("操作成功");
          that.getserveList();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleted(row) {
      let that = this;
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that
          .$post("/api/JRServe/Del", { SysID: row.SysID })
          .then(function (data) {
            console.log(data);
            if (data.Result) {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.getserveList();
            }
          })
          .error(() => {
            this.$message({
              type: "info",
              message: "删除失败",
            });
          });
      });
    },
  
    // add富文本
    getEditor(value) {
      console.log(value);
      this.ruleForm.Description = value;
    },
    getIntro(value) {
      console.log(value);
      this.ruleForm.Intro = value;
    },
    // 编辑富文本
    getserveEditor(value) {
      this.item.Description = value;
    },
    getserveIntro(value) {
      console.log(value);
      this.item.Intro = value;
    },
    getperson() {
      this.$router.push("/personalCenter");
    },
    getnews() {
      this.$router.push("/newsCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
    // 服务
    gettags(i, item) {
      console.log(item);
      this.i = i;
      this.LogoList = [];
      if (item) {
        this.item = item;
        this.LoadFiles(item.SysID);
      }
    },
  },

  mounted() {
    this.getserveList();
  },
};
</script>
<style scoped  lang="scss">

.bottom_right::v-deep .el-form-item__content {
  width: 100% !important;

  margin-top: 60px;
  img {
    float: left;
    padding-bottom: 30px;
  }
  .delimg {
    float: left;
    margin-top: -15px;
    margin-left: -15px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 9999999999;
    border-radius: 50%;
    background-color: red;
  }
}
.el-icon-camera-solid:before {
  font-size: 24px;
}
.page {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userinforbox {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

// .bottom_right ::v-deep .el-select .el-input__inner {
//   width: 320px;
// }
.htmlbox {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.htmlbox ::v-deep img {
  width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}
.top_btnbox {
  position: relative;
  top: 0;
  float: left;
  margin-left: 20px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  // background-color: red;
  em {
    position: absolute;
    font-style: none;
    height: 20px;
    line-height: 20px;
    width: 20px;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    background-color: red;
    font-size: 10px;
    color: #fff;
    text-align: center;
  }
  img {
    position: absolute;
    height: 40px;
    width: 40px;
    left: 30px;
    top: 10px;
    margin: 0;
  }
  span {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 60px;
    height: 30px;
    line-height: 30px;
    color: #1890ff;
    font-size: 15px;
  }
}
.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
}



.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  // display: inline-block;
  float: left;
  min-width: 50%;
  max-width: 100%;
  padding: 10px 15px;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  color: #63cafd;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    display: inline-block;
    height: 40px;
    width: 120px;
    line-height: 40px;
    color: #666;
    // background-color: #D9EDF7;
  }
  i {
    font-style: normal;
  }
}
.usertitle {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
.userlogo {
  height: 100px;
  // font-weight: 700;

  line-height: 100px;
  font-size: 16px;

  span {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    //  padding: 15px;
    color: #666;
  }
  img {
    height: 100px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
@media (max-width: 500px) {
.tabbox{
  width: 900px;
}
  .page {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userinforbox {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

// .bottom_right ::v-deep .el-select .el-input__inner {
//   width: 320px;
// }
.htmlbox {
  float: left;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}
.htmlbox ::v-deep img {
  width: 100%;
}
.htmlbox ::v-deep p {
  line-height: 30px;
  font-size: 15px;
  color: #555;
}
.htmlbox ::v-deep dt {
  line-height: 40px;
  font-weight: 600;
  font-size: 15px;
  color: #555;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}

.bottombox {
  width: 100%;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: auto;
}

.bottom_right {
  float: left;
  width: 100%;
  margin-left: 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
  overflow: auto;
}

.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  position: relative;
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heard {
  position: relative;
  height: 30px;
  width: 100%;
  span {
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 100px;
    border-radius: 3px;
    color: #1890ff;
    font-size: 14px;
    line-height: 32px;
    background-color: #fff;
    vertical-align: middle;
    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      // vertical-align:middle;
      vertical-align: middle;
    }
  }
}
.userinforbox {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 15px;
}
.username {
  // display: inline-block;
  float: left;
  min-width: 50%;
  max-width: 100%;
  padding: 10px 15px;
  height: 60px;
  line-height: 40px;
  font-size: 14px;
  color: #63cafd;
  box-sizing: border-box;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  span {
    display: inline-block;
    height: 40px;
    width: 120px;
    line-height: 40px;
    color: #666;
    // background-color: #D9EDF7;
  }
  i {
    font-style: normal;
  }
}
.usertitle {
  float: left;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}
.userlogo {
  height: 100px;
  // font-weight: 700;

  line-height: 100px;
  font-size: 16px;

  span {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    //  padding: 15px;
    color: #666;
  }
  img {
    height: 100px;
    // width: 80px;
    vertical-align: middle;
    margin-right: 30px;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 10px 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;

  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}
}
</style>